<template>
  <div class="lead-error-404">
    <div class="lead-error-404__container">
      <div class="lead-error-404__container__number">404</div>
      <div class="tw-font-medium tw-mt-12 tw-text-center tw-text-2xl sm:tw-text-4xl">
        {{ $t('leads.protected.error.notFound.title') }}
      </div>
      <div class="tw-text-gray-500 tw-mt-2 tw-text-center">
        {{ $t('leads.protected.error.notFound.description') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProtectedLead404',
}
</script>

<style lang="scss">
.lead-error-404 {
  @include screen-flex;

  &__container {
    @include screen-container($padding: true, $h-center: true, $v-center: true);

    &__number {
      line-height: 1;
      font-size: 8rem;
      font-weight: 600;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 10rem;
      }
    }
  }
}
</style>
